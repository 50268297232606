import React, { useEffect, useState } from 'react';
import Option1 from '../../assets/Option1.png';
import Option2 from '../../assets/Option2.png';
import Option3 from '../../assets/treehouses_form_option.webp';
import Option4 from '../../assets/Option4.png';
import { TYPES } from "../../constants/global";
import ContinueButton from '../Form/ContinueButton.js';

const Homepage = (props) => {

    const [type, setType] = useState(null);

    const [type1Class, setType1Class] = useState('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
    const [type2Class, setType2Class] = useState('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
    const [type3Class, setType3Class] = useState('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
    const [type4Class, setType4Class] = useState('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');

    useEffect(() => {
        switch (type) {
            case 1:
                setType1Class('flex flex-col justify-center items-center border-2 border-white rounded-xl p-1 xl:p-3');
                setType2Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType3Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType4Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                break;
            case 2:
                setType2Class('flex flex-col justify-center items-center border-2 border-white rounded-xl p-1 xl:p-3');
                setType1Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType3Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType4Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                break;
            case 3:
                setType3Class('flex flex-col justify-center items-center border-2 border-white rounded-xl p-1 xl:p-3');
                setType1Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType2Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType4Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                break;
            case 4:
                setType4Class('flex flex-col justify-center items-center border-2 border-white rounded-xl p-1 xl:p-3');
                setType1Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType2Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType3Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                break;
            default:
                setType1Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType2Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType3Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                setType4Class('flex flex-col justify-center items-center border-2 border-transparent p-1 xl:p-3');
                break;
        }
    }, [type]);

    const handleClick = () => {
        if (type && type !== '') {
            props.onType(type);
        }
    }

    return (
        <div className='flex flex-col items-center gap-10 md:gap-0 md:justify-around h-full pt-10 pb-20 md:py-0'>
            {/*<h1 className='text-white text-7xl'>What are you looking for?</h1>*/}
            <div className='flex flex-col md:flex-row flex-wrap lg:flex-nowrap justify-around items-center w-full md:px-20 lg:px-10 xl:px-28'>
                <button onClick={() => { setType(TYPES.TABLES_OTHER_CARPENTRY); }} className={type1Class} >
                    <img src={Option1} alt='Option1' className='w-1/2 md:w-3/5 lg:w-2/3 2xl:w-11/12' />
                    <p className='text-white text-lg md:text-2xl text-center'>Tables & Other Carpentry</p>
                </button>
                <button onClick={() => { setType(TYPES.TINY_HOUSES); }} className={type2Class} >
                    <img src={Option2} alt='Option2' className='w-1/2 md:w-3/5 lg:w-2/3 2xl:w-11/12' />
                    <p className='text-white text-lg md:text-2xl text-center'>Tiny Houses</p>
                </button>
                <button onClick={() => { setType(TYPES.TREE_HOUSES); }} className={type3Class} >
                    <img src={Option3} alt='Option3' className='w-1/2 md:w-3/5 lg:w-2/3 2xl:w-11/12' />
                    <p className='text-white text-lg md:text-2xl text-center'>Tree Houses</p>
                </button>
                <button onClick={() => { setType(TYPES.SCULPTURES); }} className={type4Class} >
                    <img src={Option4} alt='Option4' className='w-1/2 md:w-3/5 lg:w-2/3 2xl:w-11/12' />
                    <p className='text-white text-lg md:text-2xl text-center'>Sculptures</p>
                </button>
            </div>
            <ContinueButton onClick={() => handleClick()} />
        </div>
    )
}

export default Homepage;