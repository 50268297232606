// ADDONS
import AccessStairs from "./addons/access_stairs.jpeg"
import Awning from "./addons/awning.jpg"
import BurntExterior from "./addons/burnt_exterior.png"
import CorkInsulation from "./addons/cork_insulation.jpeg"
import Mosquito from "./addons/mosquito.jpg"
import OffGridWater from "./addons/offgrid_water.png"
import OpenAccessSkylight from "./addons/open_access_skylight.png"
import Salamandra from "./addons/salamandra.png"
import Shutters from "./addons/shutters.jpg"
import SkylightClosed from "./addons/skylight_closed.jpg"
import Stairs from "./addons/stairs.jpeg"

// BATHROOM
import Ceiling from "./bathroom/bathroom_ceiling.jpeg"
import DarkWood from "./bathroom/bathroom_dark_wood.jpeg"
import LightWood from "./bathroom/bathroom_light_wood.jpeg"

// KITCHEN
import Kitchen from "./kitchen_module/kitchen_1.jpeg"
import FrontView from "./kitchen_module/kitchen_2.jpeg"
import SideView from "./kitchen_module/kitchen_3.jpeg"

// OFF-GRID SYSTEMS
import Hydroponic from "./offgrid_systems/hydroponic_herbs.jpeg"
import Solar from "./offgrid_systems/solar_offgrid_systems.png"

// ROOF TYPES
import RoofTypes from "./roof_types/roof_types.png"

// SLEEPING AREA
import SingleBed from "./sleeping_area/single_bed.jpeg"
import DoubleBed from "./sleeping_area/double_bed.jpeg"

// TRAILER
import Trailer from "./trailer/trailer.png"

// WOOD COUNTERTOP
import WoodCountertop from "./wood_countertop/wood_countertop.jpeg"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "addons": [
        AccessStairs,
        Awning,
        BurntExterior,
        CorkInsulation,
        Mosquito,
        OffGridWater,
        OpenAccessSkylight,
        Salamandra,
        Shutters,
        SkylightClosed,
        Stairs
    ],
    "bathroom": [
        Ceiling,
        DarkWood,
        LightWood
    ],
    "kitchen": [
        Kitchen,
        FrontView,
        SideView
    ],
    "offgrid_systems": [
        Hydroponic,
        Solar,
    ],
    "roof_types": [
        RoofTypes        
    ],
    "sleeping_area": [
        SingleBed,
        DoubleBed        
    ],
    "trailer": [
        Trailer
    ],
    "wood_countertop": [
        WoodCountertop
    ],
};