const SubmitButton = ({ text = 'Send', isLoading = false, onClick }) => 
{
    return (
        <button
            onClick={(e) => { if(!isLoading) onClick(e)}}
            className="bg-verde rounded-full text-white xl:text-xl w-20 xl:w-24 h-10 xl:h-12 text-center mx-auto"
        >
            {isLoading ? (
                <div className="animate-spin w-5 h-5 border-white border-t-verde border-4 border-solid rounded-full mx-auto" />
            ) : (
                text
            )}
        </button>
    )
}

export default SubmitButton;