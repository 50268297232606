const Input = ({ value, placeholder, onChange, error }) =>
{
    return (
        <input
            className={`${ error ? 'border-red-500 placeholder:text-red-500' : 'border-black/50 placeholder:text-black/50'} text-lg xl:text-xl text-black border px-5 xl:px-10 py-2 md:py-3 2xl:py-5 rounded-xl focus:outline-none w-full`}
            onChange={(e) => onChange(e) }
            value={value}
            type='text'
            placeholder={error ? `${placeholder} is required` : placeholder }
        />
    )
}

export default Input;