import Ash from "./Ash.png";
import Bubinga from "./Bubinga.png";
import Cedar from "./Cedar.png";
import Chestnut from "./Chestnut.png";
import Kambala from "./Kambala.png";
import Mahogany from "./Mahogany.png";
import Mutene from "./Mutene.png";
import Oak from "./Oak.png";
import Pine from "./Pine.png";
import Walnut from "./Walnut.png";
import Metal from "./Metal.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    { value: "Ash", image: Ash},
    { value: "Bubinga", image: Bubinga},
    { value: "Cedar", image: Cedar},
    { value: "Chestnut", image: Chestnut},
    { value: "Kambala", image: Kambala},
    { value: "Mahogany", image: Mahogany},
    { value: "Mutene", image: Mutene},
    { value: "Oak", image: Oak},
    { value: "Pine", image: Pine},
    { value: "Walnut", image: Walnut},
    { value: "Metal", image: Metal},
    { value: "Other", image: Metal}
];