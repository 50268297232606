import { useState } from "react";
import { MdCheck, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Select = ({ options, placeholder, onChange }) =>
{
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);

    const handleChange = (option) =>
    {
        onChange(option);
        setSelectedOption(option);
    }

    return (
        <>
        <div onClick={() => { setIsOpen(!isOpen) }} className="relative focus:outline-none cursor-pointer w-full h-10 xl:h-16 2xl:h-20">
            <div className="absolute bg-white w-full flex items-center justify-between p-3 xl:p-4 2xl:p-5 rounded-xl shadow-custom z-10">
                <p className="text-lg lg:text-xl 2xl:text-2xl">{selectedOption.label ?? placeholder }</p>
                <div className="text-xl lg:text-2xl 2xl:text-4xl">
                    {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </div>
            </div>
            {isOpen && (
                <>
                    <div className="absolute left-0 top-0 w-full flex flex-col bg-verde rounded-t-2xl pt-20 xl:pt-24 pb-2 2xl:pb-5"></div>
                    <div className="absolute left-0 top-16 xl:top-20 2xl:top-24 w-full flex flex-col bg-verde rounded-2xl text-white font-bold pb-2 2xl:pb-5 gap-y-1 2xl:gap-y-3 z-20">
                        {options.map((option, index) => (
                            <button key={index} onClick={() => { handleChange(option) }} className={`h-full text-start pl-8 2xl:pl-16 ${ selectedOption.value === option.value && "pl-3 2xl:pl-6" } focus:outline-none flex items-center text-lg lg:text-xl 2xl:text-2xl`}>
                                <span className={`${selectedOption.value === option.value ? 'flex' : 'hidden' } mr-2 2xl:mr-4`}>
                                    <MdCheck />
                                </span>
                                {option.label}
                            </button>
                        ))}
                    </div>
                </>
            )}
        </div>
        </>
    )
}

export default Select;