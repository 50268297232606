import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";

const WoodCard = ({ wood, selectedWood, onChange }) =>
{    
    return (
    <>
    <div className="col-span-1 w-fit flex flex-col items-center gap-y-1 2xl:gap-y-2">
        <button
            onClick={() => onChange(wood)}
            className={`flex flex-col items-center rounded-2xl ${
                selectedWood === wood.value ? "border-black/20" : "border-transparent"
            } border-2 relative`}
        >
            {wood.value == "Other" ? 
                (
                    <>
                    <img className="opacity-0" src={wood.image} alt={wood.value} />
                    <p className="absolute top-1/2 -translate-y-1/2">Other</p>
                    </>
                    
                ) : (
                    <img src={wood.image} alt={wood.value} />
                )
            }
            <p className="text-lg lg:text-xl text-black">{wood.value}</p>
        </button>
        <button 
            onClick={() => onChange(wood)}
            className="text-verde text-lg lg:text-xl 2xl:text-3xl">
                {selectedWood === wood.value ? (
                    <MdOutlineRadioButtonChecked />
                ) : (
                    <MdOutlineRadioButtonUnchecked />
                )}
        </button>
    </div>
    </>
    )
}

export default WoodCard;