const ContinueButton = ({ onClick }) => 
{
    return (
        <>
            <button
                className='md:text-lg lg:text-xl bg-white rounded-full py-3 lg:py-5 px-10 lg:px-20'
                type='button'
                onClick={() => { onClick() }}
            >
                Continue
            </button>
        </>
    )
}

export default ContinueButton;