import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true,
    headers: { 'content-type': 'multipart/form-data' }
})

axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axios