// AREA
import Exterior from "./area/exterior.jpeg";
import Interior from "./area/interior.jpeg";
import Outdoors from "./area/outdoors.jpg";
import SideView from "./area/side_view.jpeg";
import TopView from "./area/top_view.png";

// LOCATION
import Location from "./location/location.png";

// OFF-GRID SYSTEMS
import Hydroponic from "./offgrid_systems/hydroponic_herbs.jpeg"
import Solar from "./offgrid_systems/solar_offgrid_systems.png"

// SHAPE
import Scale from "./shape/scale.png";

// SUPPORT
import Support from "./support/support.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "area": [ 
        Exterior,
        Interior,
        Outdoors,
        SideView,
        TopView
    ],
    "location": [
        Location
    ],
    "offgrid_systems": [
        Hydroponic,
        Solar
    ],
    "shape": [
        Scale
    ],
    "support": [
        Support
    ]
};