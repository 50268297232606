import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";

const CheckboxCard = ({ onClick, label, value }) => {

    const isSelected = () => {
        if (value instanceof Array) {
            return value.includes(label);
        } else {
            return value === label;
        }
    }

    return (
        <button
            onClick={() => {onClick()}}
            className={`${
                isSelected() ? "border-verde" : "border-transparent"
            } border text-lg lg:text-xl 2xl:text-2xl text-black/50 bg-white w-full flex items-center justify-between p-3 xl:p-4 2xl:p-5 rounded-xl shadow-custom focus:outline-none`}
        >
            <p>{label}</p>
            <div className="text-verde">
                {isSelected() ? (
                    <MdOutlineRadioButtonChecked />
                ) : (
                    <MdOutlineRadioButtonUnchecked />
                )}
            </div>
        </button>
    )
}

export default CheckboxCard;