import axios from "../client";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    send: async (data) => 
        await axios.post(`${process.env.REACT_APP_MAILER_URL}`, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        })
        .finally(() => {
            //
        })
};