import React, { useState } from "react";
import { MdOutlineAttachFile } from "react-icons/md";

import Email from "../../api/services/Email";
import Select from "../Form/Select";
import { TYPES } from "../../constants/global";

import SwiperCustom from "../SwiperCustom/SwiperCustom";
import images from "../../assets/treeHouses/images";
import Accordion from "../Form/Accordion";
import CheckboxCard from "../Form/CheckboxCard";
import SubmitButton from "../Form/SubmitButton";

const TreeHouses = (props) => {

	const [offGrid, setOffGrid] = useState([]);
	const [location, setLocation] = useState(null);
	const [otherLocation, setOtherLocation] = useState(null);

	const [formData, setFormData] = useState({
		type: TYPES.TREE_HOUSES,
		email: props.email,
	});
	const [model, setModel] = useState({});

	const [errors, setErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [swiperImages, setSwiperImages] = useState(images.shape);

	const shapes = [
		{value: "A Frame", label: "A Frame"},
		{value: "Traditional", label: "Traditional"},
		{value: "Cabin", label: "Cabin"},
		{value: "Personalized", label: "Personalized"},
	];

	const supports = [
		{value: "Ground", label: "Ground"},
		{value: "Raised", label: "Raised"},
		{value: "Tree", label: "Tree"},
	];

	const areas = [
		{value: "10m2", label: "10m2"},
		{value: "15m2", label: "15m2"},
		{value: "30m2", label: "30m2"},
	];

	const locations = [
		{value: "Lisbon", label: "Lisbon"},
		{value: "North", label: "North"},
		{value: "Center", label: "Center"},
		{value: "Alentejo", label: "Alentejo"},
		{value: "Algarve", label: "Algarve"},
		{value: "Other", label: "Other"},
	];

	const handleModelData = (value, field) => {
		setModel({ ...model, [field]: value });
	}

	const handleOffGridOptions = (value) => {
		if (offGrid.includes("None of the above")) {
			setOffGrid([]);
		}
		offGrid.includes(value)
		? setOffGrid((prevOptions) => prevOptions.filter((option) => option !== value))
		: setOffGrid((prevOptions) => [...prevOptions, value])
	}

	const submitForm = async () => {
		setErrors([]);
		setIsLoading(true);
		let jsonModel = {...model, "offGridSystems": offGrid};		
		if (otherLocation) {
			jsonModel = {...jsonModel, "location": otherLocation};
		} else {
			jsonModel = {...jsonModel, "location": location};
		}
		const response = await Email.send( {...formData, model: JSON.stringify(jsonModel)});
		if(response.status === 200) {
			props.submit();
			setIsLoading(false);
		}
		else {
			setErrors(response.response.data.reduce(((result, current) => Object.assign(result, current)), {}));
			setIsLoading(false);
		}
	};

	return (
		<div className="flex flex-col lg:flex-row items-start justify-between h-full w-full bg-white md:py-10 lg:pb-0">
			<div className="w-full lg:w-3/5 max-h-96 md:max-h-md lg:max-h-3xl xl:max-h-5xl flex flex-col h-full">
				<SwiperCustom images={swiperImages} />
				<p className="py-5 px-5 lg:px-10 xl:px-20 text-2xl xl:text-3xl"></p>
			</div>

			<div className="w-full lg:w-2/5 h-full lg:max-h-full flex flex-col pb-8 md:pb-14 px-5 lg:px-10 xl:pl-10 xl:pr-16 gap-y-6 2xl:gap-y-8 lg:overflow-auto mt-5 md:mt-0">
				{/* SHAPE */}
				<Accordion label={"Shape"} error={errors?.shape} childrenHasChanges={model.shape} onClick={() => { setSwiperImages(images.shape) }}>
					<Select options={shapes} placeholder={"Shape"} onChange={(e) => { handleModelData(e.value, "shape") }} />
				</Accordion>

				{/* SUPPORT */}
				<Accordion label={"Support"} error={errors?.support} childrenHasChanges={model.support} prevAccordionValue={model.shape} onClick={() => { setSwiperImages(images.support) }}>
					<Select options={supports} placeholder={"Support"} onChange={(e) => { handleModelData(e.value, "support") }} />
				</Accordion>

				{/* AREA */}
				<Accordion label={"Area"} error={errors?.area} childrenHasChanges={model.area} prevAccordionValue={model.support} onClick={() => { setSwiperImages(images.area) }}>
					<Select options={areas} placeholder={"Area"} onChange={(e) => { handleModelData(e.value, "area") }} />
				</Accordion>

				{/* LOCATION */}
				<Accordion label={"Location"} error={errors?.location} childrenHasChanges={location === "Other" ? null : location} prevAccordionValue={model.area} onClick={() => { setSwiperImages(images.location) }}>
					<div className="w-full">
						<Select options={locations} placeholder={"Location"} onChange={(e) => { setLocation(e.value); setOtherLocation(null) }} />
						{location === "Other" && (
							<input
								type="text"
								placeholder="Specify Location"
								className="bg-white p-3 xl:p-4 2xl:p-5 w-full rounded-xl text-lg lg:text-xl 2xl:text-2xl shadow-custom focus:outline-none mt-8 lg:mt-5"
								onChange={(e) => setOtherLocation(e.target.value)}
							/>
						)}
					</div>
				</Accordion>

				{/* OFF GRID */}
				<Accordion label={"Off Grid Systems"} error={errors?.offGridSystems} childrenHasChanges={offGrid.includes("None of the above")} prevAccordionValue={location === "Other" ? otherLocation : location} onClick={() => { setSwiperImages(images.offgrid_systems) }}>
					<div className="w-full flex flex-col items-center gap-y-3">
						<CheckboxCard onClick={() => { handleOffGridOptions("Solar off-grid system") }}
							label={"Solar off-grid system"} value={offGrid}
						/>
						<CheckboxCard onClick={() => { handleOffGridOptions("Water off-grid systems") }}
							label={"Water off-grid systems"} value={offGrid}
						/>
						<CheckboxCard onClick={() => { handleOffGridOptions("Hydroponic herbs production") }}
							label={"Hydroponic herbs production"} value={offGrid}
						/>
						<CheckboxCard onClick={() => {
							offGrid.includes("None of the above")
								? setOffGrid([])
								: setOffGrid(["None of the above"]);
							}}
							label={"None of the above"} value={offGrid}
						/>
					</div>
				</Accordion>
				
				{/* SPECIFICATIONS */}
				<Accordion label={"Specifications"} error={errors?.specifications} prevAccordionValue={offGrid.length == 0 ? null : offGrid}>
					<div className="w-full mt-5 md:mb-10 flex flex-col gap-y-5">
						<textarea
							onChange={(e) => handleModelData(e.target.value, "specifications")}
							value={model?.specifications}
							className="shadow-custom placeholder:text-black/50 w-full rounded-2xl p-5 placeholder:text-xl text-xl h-52"
							placeholder="Send us a reference..."
						/>
						<div className="flex items-center gap-x-5">
							<label
								htmlFor="file"
								className="text-lg md:text-xl lg:text-2xl flex items-center text-black/30 hover:cursor-pointer"
							>
								<MdOutlineAttachFile />
								<p className="text-black/50 text-lg lg:text-xl ml-2">{formData?.file_image ? formData?.file_image.name : 'Attach your image'}</p>
							</label>
							<input
								onChange={(e) => { setFormData({...formData, "file_image": e.target.files[0]}) }}
								type="file"
								id="file"
								className="hidden"
							/>
						</div>
					</div>
				</Accordion>

				{ /* SUBMIT */ }
				<div className="flex items-center justify-center">
					<SubmitButton isLoading={isLoading} onClick={(e) => {submitForm(e)}} />
				</div>
			</div>
		</div>
	);
};

export default TreeHouses;
